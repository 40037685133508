import { LocaleType } from '@hhgtech/hhg-components/build/interfaces/types'
import { enUS, vi, km, hi, id, ms, th, zhTW } from 'date-fns/locale'
import { LOCALE } from 'interfaces/types'

import { BmrActiveLevel, LogoType } from '../types'
const { NEXT_PUBLIC_TAROT_STRAPI_API_GET } = process.env

export * from './subot'
export const API_DATE_FORMAT = 'yyyy-MM-dd'
export const API_DAYJS_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT = 'MM/dd/yyyy'
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_FNS_DATE_FORMAT = 'dd-MM-yyyy'
export const POST_DATE_FORMAT = 'dd/MM/yyyy'
export const DUE_DATE_FORMAT = 'dd-MM-yyyy'

export const DEFAULT_AVATAR_IMG = '/images/avatar.jpg'
export const DEFAULT_RESULT_IMG = '/images/default.png'
export const DEFAULT_IMG = '/images/default-image.jpg'

export const WORKSHOP_USER_EMAIL = 'hhg_workshop_user_email'
export const WORKSHOP_USER_PHONE = 'hhg_workshop_user_phone'

export const TIME_RESET_RESEND_OTP = 30

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production'

export const basePath =
  process.env.NEXT_PUBLIC_DEPLOY_LOCALE === 'tl-PH' ? '/fil' : ''

export const assetImagePath = `${basePath}/images`

export const assetSvgPath = `${basePath}/svg`

export const DATE_FNS_LOCALE: { [key in LocaleType]?: Locale } = {
  'en-PH': enUS,
  'vi-VN': vi,
  'km-KH': km,
  'hi-IN': hi,
  'id-ID': id,
  'my-MM': enUS,
  'ms-MY': ms,
  'th-TH': th,
  'zh-TW': zhTW,
}
export enum CategoryType {
  CategoryType1 = 1,
  CategoryType2 = 2,
}
export enum ArticleType {
  ArticleType1 = 1,
  ArticleType2 = 2,
  ArticleType3 = 3,
}

export const HEALTH_TOOL_TYPE = {
  DUE_DATE: 'due-date-calculator',
  BMI: 'bmi',
  BMR: 'bmr',
  OVULATION: 'ovulation-calculator',
  IFRAME: 'IFRAME',
  PWG: 'preweight-gain-calculator',
  THR: 'heart-rate',
  BABY_VACCINE: 'vaccination-tool',
  BABY_GROWTH: 'baby-growth-chart',
  BABY_POOP: 'baby-poop-checking',
} as const

export const FEATURE_FLAG = {
  categoryType: CategoryType.CategoryType1,
  articleType: ArticleType.ArticleType3,
}

export const PUBLIFT_PREFIX = '/21682272649'

export const LOCALE_SPECS: {
  [key: string]: {
    [key: string]: string
  }
} = {
  'vi-VN': {
    LOGO_TYPE: 'marrybaby' as LogoType,
    SITE_NAME: 'MarryBaby',
    SITE_NAME_FORMATTED: 'MarryBaby',
    HOMEPAGE_IMAGE:
      'https://marrybaby.vn/images/country-thumbnails/marrybaby.png',
    SITE_ID: '10',
    // INSIDER_TAG: '//hellobacsivn.api.useinsider.com/ins.js?id=10003798',
    TOOLTIP_IMAGE: '/images/tooltips-vn.png',
    BABY_POOP: 'phan-tre-so-sinh-nhu-the-nao-la-tot',
    VACCINATION_TOOL: 'lich-tiem-chung',
    PHONE: '+84',
    FLAG: `${assetSvgPath}/flags/flag-rect-vn.svg`,
  },
  'en-PH': {
    LOGO_TYPE: 'marrybaby' as LogoType,
    SITE_NAME: 'MarryBaby',
    SITE_NAME_FORMATTED: 'MarryBaby',
    HOMEPAGE_IMAGE:
      'https://marrybaby.vn/images/country-thumbnails/marrybaby.png',
    SITE_ID: '10',
    // INSIDER_TAG: '//hellobacsivn.api.useinsider.com/ins.js?id=10003798',
    TOOLTIP_IMAGE: '/images/tooltips-vn.png',
    BABY_POOP: 'phan-tre-so-sinh-nhu-the-nao-la-tot',
    VACCINATION_TOOL: 'lich-tiem-chung',
    PHONE: '+63',
    FLAG: `${assetSvgPath}/flags/flag-rect-ph.svg`,
  },
}

export const STATIC_PAGES: {
  [key: string]: {
    [key: string]: string | null
  }
} = {
  'vi-VN': {
    TERM_OF_USE: '/dieu-khoan-su-dung',
    PRIVACY_POLICY: '/chinh-sach-rieng-tu',
    FAQS: '/cau-hoi-thuong-gap',
    ABOUT_US: '/gioi-thieu',
    CONTACT_US: '/mediakit/lien-he',

    PRIVACY: '/privacy',
    EDITORIAL_CORRECTIONS_POLICY: '/chinh-sach-bien-tap-chinh-sua',
    ADVERTISING_SPONSOR_POLICY: '/chinh-sach-quang-cao-tai-tro',
    POLICIES: '/chinh-sach',
    SITEMAP: '/sitemap',
    ADVERTISEMENT: '/quang-cao-voi-chung-toi',
    ABOUT_GO_CARE: '/about-go-care',
    HEALTH_TOOLS: '/health-tools',
    EXECUTIVE_BIOS: 'https://hellohealthgroup.com/hello-bacsi',
    RECRUITMENT: 'https://hellohealthgroup.com/careers',
    COMMUNITY_GUIDELINE: '/tieu-chuan-cong-dong',
    TOGETHER: '/community',
    OPERATION_RULES: '/quy-che-hoat-dong',
    COMPLAIN_RESOLVE_POLICIES: '/chinh-sach-giai-quyet-khieu-nai',
  },
  'en-PH': {
    POLICIES: '/policies',
    TERM_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    EDITORIAL_CORRECTIONS_POLICY: '/editorial-and-corrections-policy',
    ADVERTISING_SPONSOR_POLICY: '/advertising-and-sponsor-policy',
    FAQS: null,
    SITEMAP: null,
    ABOUT_US: '/about-us',
    ADVERTISEMENT: null,
    CONTACT_US: '/contact-us',
    ABOUT_GO_CARE: null,
    HEALTH_TOOLS: '/health-tools',
    EXECUTIVE_BIOS: 'https://hellohealthgroup.com/management-team',
    RECRUITMENT: 'https://hellohealthgroup.com/careers',
    COMMUNITY_GUIDELINE: '/community-guidelines',
    TOGETHER: '/community',
  },
}

export const REDIRECTION_PAGES: {
  [key: string]: {
    [key: string]: string
  }
} = {
  // 'vi-VN': {
  // },
  'km-KH': {
    advertising: '/static/advertisement',
    'រង្វាស់សុខភាព/គណនាកម្រិតជាតិអាល់កុលក្នុងឈាម/':
      '/health-tools/រង្វាស់សុខភាព/គណនាកម្រិតជាតិអាល់កុលក្នុងឈាម',
    'រង្វាស់សុខភាព/គណនារកចង្វាក់បេះដូងល្អបំផុតពេលហាត់ប្រាណ':
      '/health-tools/រង្វាស់សុខភាព/គណនារកចង្វាក់បេះដូងល្អបំផុតពេលហាត់ប្រាណ',
  },
  // 'vi-VN': {
  // },
  // 'my-MM': {
  // },
  // 'zh-TW': {
  // },
  'id-ID': {
    lowongan: 'https://hellohealthgroup.com/careers/',
    'buat-janji-siloam-hospitals': '/health-tools/buat-janji-siloam-hospital',
  },
  // 'hi-IN': {
  // },
  // 'ms-MY': {
  // },
  'th-TH': {
    'เครื่องมือตรวจเช็คสุขภาพ/เครื่องคำนวณหาค่าดัชนี':
      '/health-tools/เครื่องคำนวณหา-ค่าดัชนี',
    'เครื่องมือตรวจเช็คสุขภาพ/การคำนวณปริมาณแอลกอฮอล์ในเลือด-bac':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/เครื่องคำนวณปริมาณแอลก',
    'เครื่องมือตรวจเช็คสุขภาพ/เครื่องวัดอัตราการเต้นของหัวใจเป้าหมาย':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/เครื่องวัดอัตราการเต้น',
    'เครื่องมือตรวจเช็คสุขภาพ/คำนวณค่าบุหรี่':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/คำนวณค่าบุหรี่-smoking-cost-calculator',
    'เครื่องมือตรวจเช็คสุขภาพ/สีอุจจาระของลูก-บอกถึงสุขภาพลูกน้อยได้':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/สีอุจจาระของลูก-บอกถึงส',
    'เครื่องมือตรวจเช็คสุขภาพ/คำนวณน้ำหนักตัวเวลาตั้งครรภ์':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/เครื่องคำนวณน้ำหนักตัว',
    'เครื่องมือตรวจเช็คสุขภาพ/คำนวณการเผาผลาญแคลอรี่':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/คำนวณการเผาผลาญแคลอรี่',
    'เครื่องมือตรวจเช็คสุขภาพ/คำนวณวันคลอด':
      '/health-tools/เครื่องมือคำนวณกำหนดคลอด',
    'เครื่องมือตรวจเช็คสุขภาพ/กำหนดการฉีดวัคซีนป้องกันโรค':
      '/health-tools/เครื่องมือตรวจเช็คสุขภ/vaccination-tool',
  },
}

export const LOCALE_DRUGS: { [id: string]: string } = {
  'vi-VN': 'thuoc',
  'en-PH': 'drugs-supplements',
}

export const PREGNANCY_SLUG: { [id: string]: string } = {
  'vi-VN': 'mang-thai',
  'en-PH': 'pregnancy',
}

export const MAPPED_CATEGORY_SLUGS: {
  [locale in LOCALE]?: {
    [k: string]: string
  }
} = {
  [LOCALE.Vietnam]: {
    pregnancy: 'mang-thai',
    parenting: 'nuoi-day-con-cai',
  },
}

export const SOCIAL_MEDIA: {
  [key: string]: {
    [key: string]: string | null
  }
} = {
  'vi-VN': {
    LINKEDIN: 'https://www.linkedin.com/company/hello-health-group/',
    FACEBOOK: 'https://www.facebook.com/marry.baby.vn',
    INSTAGRAM: 'https://www.instagram.com/marrybaby.vn/',
    TWITTER: null,
    YOUTUBE: 'https://www.youtube.com/channel/UCeZybTkY1VzJ3N7LvPGdKbg',
    ZALO: 'http://zalo.me/806483414530291942',
  },
  'en-PH': {
    LINKEDIN: 'https://www.linkedin.com/company/hello-health-group/',
    FACEBOOK: 'https://www.facebook.com/HelloDoctorPH/',
    INSTAGRAM: 'https://www.instagram.com/hellodoctor_ph/',
    TWITTER: null,
  },
}

export const LANGUAGES: { [id: string]: string } = {
  VN: 'Tiếng Việt',
  PH: 'English',
}

export const FAVICONS: {
  [key: string]: (
    | {
        type: 'link'
        rel: string
        href: string
        sizes?: string
      }
    | {
        type: 'meta'
        name: string
        content: string
      }
  )[]
} = {
  'vi-VN': [
    {
      type: 'link',
      rel: 'shortcut icon',
      href: '/favicons/favicon.ico',
    },
    {
      type: 'link',
      rel: 'icon',
      href: '/favicons/icon-32x32.png',
      sizes: '32x32',
    },
    {
      type: 'link',
      rel: 'icon',
      href: '/favicons/icon-192x192.png',
      sizes: '192x192',
    },
    {
      type: 'link',
      rel: 'apple-touch-icon-precomposed',
      href: '/favicons/icon-180x180.png',
    },
    {
      type: 'meta',
      name: 'msapplication-TileImage',
      content: '/favicons/icon-270x270.png',
    },
  ],
  'en-PH': [
    {
      type: 'link',
      rel: 'icon',
      href: '/by-locales/vi-VN/icon-32x32.png',
      sizes: '32x32',
    },
    {
      type: 'link',
      rel: 'icon',
      href: '/by-locales/vi-VN/icon-192x192.png',
      sizes: '192x192',
    },
    {
      type: 'link',
      rel: 'apple-touch-icon',
      href: '/by-locales/vi-VN/icon-180x180.png',
    },
    {
      type: 'meta',
      name: 'msapplication-TileImage',
      content: '/by-locales/vi-VN/icon-270x270.png',
    },
  ],
}

export const TEMPLATE_AZ_NAME = 'category--a-z-template-new'

export const NON_AMP_LOCALES = ['km-KH']

export const BEARER_TOKEN_COOKIE = 'hhg_user_token'
export const LEAD_TOKEN_COOKIE = 'hhg-id'
export const GA_TOKEN_COOKIE = '_ga'
export const CORAL_TOKEN_COOKE = 'coral_user_token'
export const TEMP_BABY_GROWTH_KEY = 'temp_baby_growth'
export const BABY_GROWTH_DATA_KEY = 'baby_growth_data'
export const BmrActiveLevels: {
  name: string
  value: BmrActiveLevel
  icon: string
  description: string
}[] = [
  {
    value: 'sedentary',
    icon: 'bmr-sedentary',
    //'/images/toolsBMRSedentary.svg',
    name: 'bmr.activeLevel.sedentary',
    description: 'bmr.activeLevel.sedentary.description',
  },
  {
    value: 'lightly-active',
    icon: 'bmr-light',
    // icon: '/images/toolsBMRLightlyActive.svg',
    name: 'bmr.activeLevel.lightlyActive',
    description: 'bmr.activeLevel.lightlyActive.description',
  },
  {
    value: 'moderately-active',
    icon: 'bmr-moderately',
    // icon: '/images/toolsBMRModeratelyActive.svg',
    name: 'bmr.activeLevel.moderatelyActive',
    description: 'bmr.activeLevel.moderatelyActive.description',
  },
  {
    value: 'intensely-active',
    icon: 'bmr-intensively',
    // icon: '/images/toolsBMRIntenselyActive.svg',
    name: 'bmr.activeLevel.intenselyActive',
    description: 'bmr.activeLevel.intenselyActive.description',
  },
  {
    value: 'extremely-active',
    icon: 'bmr-extremely',
    // icon: '/images/toolsBMRExtremelyActive.svg',
    name: 'bmr.activeLevel.extremelyActive',
    description: 'bmr.activeLevel.extremelyActive.description',
  },
]

export const BMI_LEVELS = [
  'underweight',
  'healthy',
  'overweight',
  'obese_level_1',
  'obese_level_2',
]

export const BMR_LEVELS = ['low', 'normal', 'high']

export const Z_INDEX = {
  adBlock: 48,
  articleAdContainer: 47,
  headerContainerHome: 99,
  headerContainerPopup: 200,
  headerContainerPopupSUbLevel: 300,
  menuSearchInputPopup: 5,
  menuSearchInputDisplayIcon: 6,
  menuSearchPopup: 200,
  desktopHeaderWrapper: 122,
}

export const GOOGLE_CALENDAR_AUTH_CLIENT_ID =
  '1038242799566-esoq4ij201ve5qfp266nbca44scpo3c6.apps.googleusercontent.com'

export const COMMENT_SORT = {
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  REPLIES_DESC: 'REPLIES_DESC',
  REACTION_DESC: 'REACTION_DESC',
}

export const LEAD_CAMPAIGN_CODE_LANDING_PAGE_WORKSHOP_1 = 'htam2dig8tohstl'
export const LEAD_CAMPAIGN_CODE_LANDING_PAGE_WORKSHOP_2 = 'e0j23mga4is6yyb'

export const FIND_CARE_IFRAME_URL =
  'https://www.appsheet.com/start/4ba34ebe-3cb6-4c16-8dd4-d074025a5bb6#appName=DependentDropdowns-2711103&group=%5B%5D&page=card&sort=%5B%5D&table=Menu&view=Menu'

export const CUSTOM_IFRAME_SLUGS: {
  [key: string]: string[]
} = {
  'vi-VN': [],
  'en-PH': [],
}

export const SSO_GOOGLE_CLIENT_ID =
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
    ? '289442006438-040a42cbidr6v5d178f3iqi9q95821r3.apps.googleusercontent.com'
    : '289442006438-cihobuq1h3r4426pur7u0e43fqdnep59.apps.googleusercontent.com'

// '983452855045-248q5268bo1tskqahc4sngo8be7s0onk.apps.googleusercontent.com'

export const NodeArticleMeta = {
  REVIEWED_DISPLAY_NAME: 'reviewed_display_name',
  REVIEWED_AVATAR_THUMBNAIL: 'reviewed_avatar_thumbnail',
  REVIEWED_URL: 'reviewed_url',
  POST_ID: 'post_id',
  POST_MODIFIED: 'post_modified',
  CATEGORY_ID: 'category_id',
  CATEGORY_NAME: 'category_name',
  CATEGORY_URL: 'category_url',
  CATEGORY_TEXT_COLOR: 'category_text_color',
}

export const RadioDataTypes = {
  text: 'text',
  image: 'image',
  textWithImage: 'textWithImage',
}

export const ENABLE_TOGETHER_MENU_LOCALES = ['vi-VN', 'id-ID']

export const ENABLE_CARE_MENU_LOCALES = ['vi-VN', 'id-ID', 'vi-VN']

export const MB_SOCIAL_LINKS = {
  LINKEDIN: 'https://www.linkedin.com/company/hello-health-group/',
  FACEBOOK: 'https://www.facebook.com/marry.baby.vn',
  INSTAGRAM: 'https://www.instagram.com/marrybaby.vn/',
  TWITTER: '',
  YOUTUBE: 'https://www.youtube.com/user/marrybabyvn',
  ZALO: 'http://zalo.me/806483414530291942',
}

export const SHOW_WORKSHOP_THANK_YOU_ACTION = 'workshop-success'

/**
 * Value can be:
 * 'sumitted' -> submitted
 * 'done' -> showed thank you
 */
export const LEADGEN_SUBMIT_KEY_DUEDATE = 'duedate_leadgen_submit'

export const BABY_GROWTH_MAX_AGE = 19
export const BABY_GROWTH_TOOLTIP_DATE_FORMAT = 'MMM dd, yyyy'
export const SSO_URL = process.env.NEXT_PUBLIC_SSO

export const Lunar2023Date = '2023-01-21T17:00:00.000Z'
export const isBeforeLunar2023 = () => new Date() < new Date(Lunar2023Date)
export const DEFAULT_LOCALE = 'vi-VN'
export const ENV = {
  DEPLOY_ENV: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  NEXT_PUBLIC_DEPLOY_ENV: process.env.NEXT_PUBLIC_DEPLOY_ENV,
  NEXT_PUBLIC_SUBOT_URL: process.env.NEXT_PUBLIC_SUBOT_URL,
  SUBOT_URL: process.env.NEXT_PUBLIC_SUBOT_URL,
  SUBOT_INTERNAL: process.env.SUBOT_INTERNAL,
  NEXT_PUBLIC_SUBOT_API: process.env.NEXT_PUBLIC_SUBOT_API,
  WP_INTERNAL_API: process.env.WP_INTERNAL_API,
  SENTRY_DSN: process.env.SENTRY_DSN,
  NEXT_PUBLIC_BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
  NEXT_PUBLIC_BASE_PROD_API_URL: process.env.NEXT_PUBLIC_BASE_PROD_API_URL,
  NEXT_PUBLIC_DISCOVER_BASE_API_URL:
    process.env.NEXT_PUBLIC_DISCOVER_BASE_API_URL,
  NEXT_PUBLIC_DISCOVER_BASE_PROD_API_URL:
    process.env.NEXT_PUBLIC_DISCOVER_BASE_PROD_API_URL,
  NEXT_PUBLIC_SSO: process.env.NEXT_PUBLIC_SSO,
  SSO_URL: process.env.NEXT_PUBLIC_SSO,
  NEXT_PUBLIC_SIDIS_API: process.env.NEXT_PUBLIC_SIDIS_API,
  NEXT_PUBLIC_CARE_API: process.env.NEXT_PUBLIC_CARE_API,
  INTERNAL_VACCINATION_TOOL_API: process.env.INTERNAL_VACCINATION_TOOL_API,
  NEXT_PUBLIC_VACCINATION_TOOL_API:
    process.env.NEXT_PUBLIC_VACCINATION_TOOL_API,
  NEXT_PUBLIC_LOGGING_ENABLED: process.env.NEXT_PUBLIC_LOGGING_ENABLED,
  NEXT_PUBLIC_DEPLOY_LOCALE: process.env.NEXT_PUBLIC_DEPLOY_LOCALE,
  DEPLOY_LOCALE: process.env.NEXT_PUBLIC_DEPLOY_LOCALE,
  NEXT_PUBLIC_SIDIS_API_GET: process.env.NEXT_PUBLIC_SIDIS_API_GET,
  NEXT_PUBLIC_LEAD_API: process.env.NEXT_PUBLIC_LEAD_API,
  NEXT_PUBLIC_TOGETHER_API: process.env.NEXT_PUBLIC_TOGETHER_API,
  NEXT_PUBLIC_GTM_AMP: process.env.NEXT_PUBLIC_GTM_AMP,
  NEXT_PUBLIC_SIDIS_TEMPLATE_API: process.env.NEXT_PUBLIC_SIDIS_TEMPLATE_API,
  NEXT_PUBLIC_TAROT_STRAPI_API_GET: NEXT_PUBLIC_TAROT_STRAPI_API_GET,
}

export const TRACKING_CATEGORY = ''
export const WEBSITE_URL = 'https://marrybaby.vn'

export const MAP_DOMAIN_BY_LOCALE = {
  [LOCALE.Vietnam]: 'hellobacsi.com',
  [LOCALE.Indonesia]: 'hellosehat.com',
  [LOCALE.Malaysia]: 'hellodoktor.com',
  [LOCALE.Cambodia]: 'hellokrupet.com',
  [LOCALE.PhilippinesEnglish]: 'hellodoctor.com.ph',
  [LOCALE.PhilippinesTagalog]: 'hellodoctor.com.ph',
  [LOCALE.Thailand]: 'hellokhunmor.com',
  [LOCALE.Myanmar]: 'hellosayarwon.com',
  [LOCALE.Taiwan]: 'helloyishi.com.tw',
  [LOCALE.China]: 'hellojiankang.com',
  [LOCALE.India]: 'helloswasthya.com',
}
export enum QUERY_HEALTH_TOOL {
  bmi = 'mi',
  gender = 'gndr',
  age = 'ge',
  slug = 'slug',
  resultId = 'resultId',
  bmr = 'mr',
  height = 'gt',
  weight = 'wght',
  source = 'surc',
  type = 'tp',
  startDate = 'sd',
  additional = 'tnl',
  week = 'wk',
  twins = 'twn',
  weightBefore = 'wb',
  yourself = 'yrslf',
  intensity = 'sy',
  rate = 'rt',
  method = 'mthd',
  day = 'dy',
  pwg = 'pwg',
  cycle = 'le',
  period = 'prd',
  access_token = 'tkn',
  name = 'nm',
  id = 'id',
}
export const DAY_JS_DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_VACCINATION = 'MM-DD-YYYY'
export const DATE_FORMAT_TOOL_RECORD_DATE_RANGE = 'DD MMM YYYY'

export const MY_HEALTH_ROUTES = {
  MY_HEALTH_TRACKER: 'user/health/trackers',
  MY_HEALTH_PARENTING: 'user/health/parenting',
}

export const DUE_DATE_CALC_METHOD_LAST_PERIOD = 1
export const DUE_DATE_CALC_METHOD_CONCEPTION = 2
export const DUE_DATE_CALC_METHOD_IVF = 3

export const HAS_COMMUNITY_SITE: string[] = [
  LOCALE.Vietnam,
  LOCALE.Indonesia,
  LOCALE.PhilippinesEnglish,
  LOCALE.PhilippinesTagalog,
  LOCALE.Thailand,
  LOCALE.Malaysia,
  LOCALE.Myanmar,
]

export const DUE_DATE_OPTIONS_DATE_FORMAT: Record<LOCALE, string> = {
  [LOCALE.Vietnam]: 'DD MMMM, YYYY',
  [LOCALE.Cambodia]: 'MMMM DD, YYYY',
  [LOCALE.Indonesia]: 'MMMM DD, YYYY',
  [LOCALE.Malaysia]: 'MMMM DD, YYYY',
  [LOCALE.Myanmar]: 'MMMM DD, YYYY',
  [LOCALE.PhilippinesEnglish]: 'MMMM DD, YYYY',
  [LOCALE.PhilippinesTagalog]: 'MMMM DD, YYYY',
  [LOCALE.Taiwan]: 'MMMM DD, YYYY',
  [LOCALE.China]: 'MMMM DD, YYYY',
  [LOCALE.Thailand]: 'MMMM DD, YYYY',
  [LOCALE.India]: 'MMMM DD, YYYY',
}

export const DEFAULT_FRUIT_ICON = `${assetImagePath}/default-fruit-icon.svg`
export const ADVERTISING_TOKEN_COOKIE = '__uid20_tokens'
export const REFRESH_TOKEN_COOKIE = 'refresh_token'
export const USER_TOKEN_COOKIE = 'user_token'
export const ADDITIONAL_QUESTION_PREFIX = 'additional_questions'
export const BABY_VACCINATION_DATA_KEY = 'baby_vaccination_data'
export const TEMP_BABY_VACCINATION_KEY = 'temp_baby_vaccination'

export const HTTP_PROTOCOL_PREFIX = ['https://', 'http://']
