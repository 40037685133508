export enum LOCALE {
  Vietnam = 'vi-VN',
  Indonesia = 'id-ID',
  Malaysia = 'ms-MY',
  Cambodia = 'km-KH',
  PhilippinesEnglish = 'en-PH',
  PhilippinesTagalog = 'tl-PH',
  Thailand = 'th-TH',
  Myanmar = 'my-MM',
  Taiwan = 'zh-TW',
  China = 'zh-CN',
  India = 'hi-IN',
}
